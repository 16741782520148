import React, { useState, useContext } from 'react';
import axios from 'axios';
import { BASE_URL } from '../context/Api';
import AuthContext from '../context/AuthContext';

const Settings = () => {
  const { employeeNumber } = useContext(AuthContext);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      setMessage('New password and confirmation password do not match');
      setIsModalVisible(true);
      return;
    }

    try {
      const response = await axios.post(`${BASE_URL}/teachers-auth/employee-change-password`, {
        employeeNumber: employeeNumber,
        oldPassword,
        newPassword,
      });

      setMessage(response.data.message);
      if (response.data.success) {
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
        setIsModalVisible(true);
      } else {
        setIsModalVisible(true);
      }
    } catch (error) {
      setMessage('Error changing password');
      setIsModalVisible(true);
    }
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setMessage('');
  };

  return (
    <div className="flex flex-col items-center justify-center p-6 bg-gray-50 min-h-screen">
      <h1 className="text-3xl font-bold mb-6">Change Password</h1>
      
      {/* Old Password Field */}
      <div className="relative w-full max-w-md mb-4">
        <input
          type={showOldPassword ? "text" : "password"}
          placeholder="Old Password"
          className="w-full p-2 border border-gray-300 rounded-md"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
        />
        <button
          className="absolute right-3 top-3"
          onClick={() => setShowOldPassword(!showOldPassword)}
        >
          {showOldPassword ? 'Hide' : 'Show'}
        </button>
      </div>

      {/* New Password Field */}
      <div className="relative w-full max-w-md mb-4">
        <input
          type={showNewPassword ? "text" : "password"}
          placeholder="New Password"
          className="w-full p-2 border border-gray-300 rounded-md"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <button
          className="absolute right-3 top-3"
          onClick={() => setShowNewPassword(!showNewPassword)}
        >
          {showNewPassword ? 'Hide' : 'Show'}
        </button>
      </div>

      {/* Confirm Password Field */}
      <div className="relative w-full max-w-md mb-4">
        <input
          type={showConfirmPassword ? "text" : "password"}
          placeholder="Confirm New Password"
          className="w-full p-2 border border-gray-300 rounded-md"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <button
          className="absolute right-3 top-3"
          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
        >
          {showConfirmPassword ? 'Hide' : 'Show'}
        </button>
      </div>

      {/* Change Password Button */}
      <button
        className="bg-[#BB005A] text-white px-6 py-2 rounded-md"
        onClick={handleChangePassword}
      >
        Change Password
      </button>

      {/* Modal for Messages */}
      {isModalVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <p>{message}</p>
            <button
              className="mt-4 bg-[#BB005A] text-white px-6 py-2 rounded-md"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;
