import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { BASE_URL } from '../context/Api';
import AuthContext from '../context/AuthContext';

const ResultsFormLevel = ({ classId }) => {
  const { user } = useContext(AuthContext);
  const [results, setResults] = useState([]);
  const [term, setTerm] = useState('');
  const [year, setYear] = useState('');
  const [form, setForm] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [subjectResults, setSubjectResults] = useState([]);
  const [isCommentModalVisible, setIsCommentModalVisible] = useState(false);
  const [currentComment, setCurrentComment] = useState('');
  const [currentGradeLevelResultID, setCurrentGradeLevelResultID] = useState('');
  const [isTermModalVisible, setIsTermModalVisible] = useState(false);
  const [noResults, setNoResults] = useState(false);

  useEffect(() => {
    if (classId) {
      fetchResults();
    }
  }, [classId]);

  const fetchResults = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/getallresults`, {
        TermID: term,
        Year: year,
        ClassID: classId,
        form: form,
      });
      if (response.data.length === 0) {
        setNoResults(true);
      } else {
        setResults(response.data);
        setNoResults(false);
      }
    } catch (error) {
      console.error('Error fetching results:', error);
    }
  };

  const fetchSubjectResults = async (studentRegNumber) => {
    try {
      const response = await axios.post(`${BASE_URL}/getSubjectResults`, {
        termID: term,
        year: year,
        classID: classId,
        studentRegNumber: studentRegNumber,
      });
      setSubjectResults(response.data.subjectResults);
      setIsModalVisible(true);
    } catch (error) {
      console.error('Error fetching subject results:', error);
    }
  };

  const handleViewSubjectResults = (student) => {
    setSelectedStudent(student);
    fetchSubjectResults(student.RegNumber);
  };

  const handleOpenCommentModal = (currentGradeLevelResultID, comment) => {
    setCurrentComment(comment);
    setCurrentGradeLevelResultID(currentGradeLevelResultID);
    setIsCommentModalVisible(true);
  };

  const handleUpdateComment = async () => {
    try {
      await axios.post(`${BASE_URL}/updateTeacherComment/${currentGradeLevelResultID}`, {
        TeacherComment: currentComment,
      });
      alert('Comment updated successfully');
      fetchResults();
      setIsCommentModalVisible(false);
    } catch (error) {
      console.error('Error updating comment:', error);
      alert('Failed to update comment');
    }
  };

  const handleTermSelect = (selectedTerm) => {
    setTerm(selectedTerm);
    setIsTermModalVisible(false);
  };

  return (
    <div className="p-6 bg-white">
      <h2 className="text-3xl font-bold mb-4 text-[#11024D]">Results</h2>
      <div className="flex mb-6 space-x-4">
        <button
          className="border border-gray-300 p-2 rounded-md w-full text-[#11024D]"
          onClick={() => setIsTermModalVisible(true)}
        >
          {term ? `Term ${term}` : 'Select Term'}
        </button>
        <input
          type="text"
          className="border border-gray-300 p-2 rounded-md w-full"
          placeholder="Year"
          value={year}
          onChange={(e) => setYear(e.target.value)}
        />
        <input
          type="text"
          className="border border-gray-300 p-2 rounded-md w-full"
          placeholder="Form"
          value={form}
          onChange={(e) => setForm(e.target.value)}
        />
      </div>

      <button
        className="bg-[#11024D] text-white p-2 rounded-md w-full hover:bg-[#0E0039]"
        onClick={fetchResults}
      >
        Fetch Results
      </button>

      {/* Display no results message if there are no results */}
      {noResults && (
        <div className="mt-6 text-center text-red-600 font-semibold">
          No results found for the selected term and year.
        </div>
      )}

      {!noResults && (
        <div className="overflow-x-auto mt-6 border border-[#11024D] rounded-md">
          <table className="min-w-full">
            <thead className="bg-[#11024D] text-white">
              <tr>
                <th className="p-2">Reg Number</th>
                <th className="p-2">Name</th>
                <th className="p-2">Surname</th>
                <th className="p-2">Total Mark</th>
                <th className="p-2">Class Position</th>
                <th className="p-2">Teacher Comment</th>
                <th className="p-2">Action</th>
              </tr>
            </thead>
            <tbody>
              {results.map((result, index) => (
                <tr key={result.RegNumber} className={index % 2 === 0 ? 'bg-[#f4f0ff]' : 'bg-white'}>
                  <td className="p-2 text-center">{result.RegNumber}</td>
                  <td className="p-2 text-center">{result.Name}</td>
                  <td className="p-2 text-center">{result.Surname}</td>
                  <td className="p-2 text-center">{result.TotalMark}</td>
                  <td className="p-2 text-center">{result.ClassPosition}</td>
                  <td className="p-2 text-center">{result.TeacherComment || 'No Comment'}</td>
                  <td className="p-2 text-center space-x-2">
                    <button
                      className="bg-green-500 text-white px-2 py-1 rounded-md hover:bg-green-600"
                      onClick={() => handleOpenCommentModal(result.GradeLevelResultID, result.TeacherComment || '')}
                    >
                      Edit Comment
                    </button>
                    <button
                      className="bg-blue-500 text-white px-2 py-1 rounded-md hover:bg-blue-600"
                      onClick={() => handleViewSubjectResults(result)}
                    >
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Comment Modal */}
      {isCommentModalVisible && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg">
            <h3 className="text-xl font-bold mb-4 text-[#11024D]">Edit Teacher Comment</h3>
            <textarea
              className="border border-gray-300 p-2 rounded-md w-full"
              value={currentComment}
              onChange={(e) => setCurrentComment(e.target.value)}
              rows="4"
            />
            <div className="flex justify-end mt-4 space-x-4">
              <button
                className="bg-gray-400 text-white px-4 py-2 rounded-md hover:bg-gray-500"
                onClick={() => setIsCommentModalVisible(false)}
              >
                Cancel
              </button>
              <button
                className="bg-[#11024D] text-white px-4 py-2 rounded-md hover:bg-[#0E0039]"
                onClick={handleUpdateComment}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Subject Results Modal */}
      {isModalVisible && selectedStudent && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg w-full max-w-3xl">
            <h3 className="text-xl font-bold mb-4 text-[#11024D]">Subject Results for {selectedStudent.Name} {selectedStudent.Surname}</h3>
            <div className="overflow-x-auto">
              <table className="min-w-full">
                <thead className="bg-[#11024D] text-white">
                  <tr>
                    <th className="text-start p-2">Subject</th>
                    <th className="text-start p-2">Paper Marks</th>
                    <th className="text-start p-2">Comment</th>
                  </tr>
                </thead>
                <tbody>
                  {subjectResults.map((subjectResult, index) => (
                    <tr key={subjectResult.ResultID} className={index % 2 === 0 ? 'bg-[#f4f0ff]' : 'bg-white'}>
                      <td className="p-2 text-start">{subjectResult.SubjectName}</td>
                      <td className="p-2">
                        {subjectResult.PaperMarks.map((paperMark, paperIndex) => (
                          <div key={`${subjectResult.ResultID}-${paperIndex}`}>
                            {paperMark.PaperName}: {paperMark.Mark}
                          </div>
                        ))}
                      </td>
                      <td className="p-2 text-start">{subjectResult.Comment}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex justify-end mt-4">
              <button className="bg-[#11024D] text-white px-4 py-2 rounded-md hover:bg-[#0E0039]" onClick={() => setIsModalVisible(false)}>
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Term Selection Modal */}
      {isTermModalVisible && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg w-full max-w-md">
            <h3 className="text-xl font-bold mb-4 text-[#11024D]">Select Term</h3>
            {[1, 2, 3].map((termOption) => (
              <button
                key={termOption}
                className="w-full p-2 border-b border-gray-300 text-left text-[#11024D] hover:bg-gray-100"
                onClick={() => handleTermSelect(termOption)}
              >
                Term {termOption}
              </button>
            ))}
            <div className="flex justify-end mt-4">
              <button className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600" onClick={() => setIsTermModalVisible(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResultsFormLevel;
