import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { BASE_URL } from '../context/Api';
import AuthContext from '../context/AuthContext';

const ResultsSubjectLevel = ({ classId, subjectName }) => {
    const { user } = useContext(AuthContext);
    const [results, setResults] = useState([]);
    const [students, setStudents] = useState([]);
    const [term, setTerm] = useState('');
    const [year, setYear] = useState('');
    const [form, setForm] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [newPaperMarks, setNewPaperMarks] = useState([{ PaperName: '', Mark: '', Term: '', Year: '' }]);
    const [comment, setComment] = useState('');
    const [resultID, setResultID] = useState(null);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [resultToDelete, setResultToDelete] = useState(null);
    const [hasSearched, setHasSearched] = useState(false);
    const [subjectNameFromApi, setSubjectNameFromApi] = useState('');
    const [gradelevelclass, setGradelevelclass] = useState('');
    const [selectedResult, setSelectedResult] = useState(null); // State for selected result
    const [isViewModalVisible, setIsViewModalVisible] = useState(false); // Modal state for viewing paper marks
    const [successModalVisible, setSuccessModalVisible] = useState(false);

    useEffect(() => {
        if (classId) {
            fetchResults();
            fetchStudents();
            fetchClassDetails();

        }
    }, [classId, term, year, form]);

    const fetchResults = async () => {
        try {
            const response = await axios.post(`${BASE_URL}/addsubjectresult/subjectresults`, {
                ClassID: classId,
                TermID: term,
                Year: year,
            });
            setResults(response.data);
            setHasSearched(true);
        } catch (error) {
            console.error('Error fetching results:', error);
        }
    };

    const fetchClassDetails = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/subjectlevelclasses/get-class/${classId}`);
            const { gradelevelclass, Subject } = response.data;
            setSubjectNameFromApi(Subject);
            setGradelevelclass(gradelevelclass); // Save the subject name in the state
        } catch (error) {
            console.error('Error fetching class details:', error);
        }
    };

    const handleDeleteResult = async () => {
        try {
            await axios.delete(`${BASE_URL}/addsubjectresult/${resultToDelete}`);
            alert('Result deleted successfully');
            setDeleteModalVisible(false);
            fetchResults(); // Refresh the results after deletion
        } catch (error) {
            console.error('Error deleting result:', error);
            alert('Error deleting result');
        }
    };
    const openViewModal = (result) => {
        setSelectedResult(result); // Store the selected result
        setIsViewModalVisible(true); // Open the modal
    };

    const fetchStudents = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/subjectlevelclasses-students/get-students-class/${classId}`);
            setStudents(response.data.students);
        } catch (error) {
            console.error('Error fetching students:', error);
        }
    };

    const handleAddResult = async () => {
        if (!selectedStudent || !term || !year) {
            alert('Please select a student, term, and year');
            return;
        }

        const resultdata = {
            subjectResultData: {
                StudentRegNumber: selectedStudent.RegNumber,
                SubjectName: subjectNameFromApi,
                TermID: term,
                Year: year,
                ClassID: gradelevelclass,
                Comment: comment,
                SubjectClassID: classId
            },
            paperMarks: newPaperMarks,
        };

        try {
            const response = await axios.post(`${BASE_URL}/addsubjectresult`, resultdata);

            if (response.status === 200 || response.status === 201) {
                setSuccessModalVisible(true); // Show success modal

                // Delay closing the main modal by 300ms to ensure success modal is shown
                setTimeout(() => {
                    setIsModalVisible(false); // Close the modal after showing the success message
                }, 300);

                resetForm(); // Clear the input fields

                // Hide the success modal after 3 seconds
                setTimeout(() => {
                    setSuccessModalVisible(false);
                }, 3000);
            } else {
                alert('Failed to add result. Please try again.');
            }
        } catch (error) {
            console.error('Error adding result:', error);
            alert('Error adding result');
        }
    };



    const handleEditResult = async () => {
        try {
            await axios.post(`${BASE_URL}/addsubjectresult/updateSubjectResult`, {
                ResultID: resultID,
                PaperMarks: newPaperMarks,
                Comment: comment,
            });
            alert('Result updated successfully');
            setIsModalVisible(false); // Close the modal after saving
            resetForm(); // Reset form after editing
        } catch (error) {
            console.error('Error updating result:', error);
            alert('Error updating result');
        }
    };


    const handleRemovePaperMark = (index) => {
        const updatedMarks = [...newPaperMarks];
        updatedMarks.splice(index, 1);
        setNewPaperMarks(updatedMarks);
    };

    const handleAddPaperMark = () => {
        setNewPaperMarks([...newPaperMarks, { PaperName: '', Mark: '', Term: '', Year: '' }]);
    };

    const openAddModal = () => {
        setIsEditMode(false);
        setIsModalVisible(true); // This is the key line to open the modal
        resetForm();
    };

    const openEditModal = (result) => {
        setIsEditMode(true);
        setSelectedStudent(result);
        setNewPaperMarks(result.PaperMarks);
        setComment(result.Comment);
        setResultID(result.ResultID);
        setTerm(result.TermID);
        setYear(result.Year);
        setIsModalVisible(true);
    };

    const resetForm = () => {
        setNewPaperMarks([{ PaperName: '', Mark: '', Term: '', Year: '' }]);
        setComment('');
        setSelectedStudent(null);
        setTerm('');
        setYear('');
    };


    return (
        <div className="p-6 bg-white">
            <div className="flex space-x-2 items-center mb-4">
                <input
                    type="text"
                    placeholder="Term"
                    className="border p-1 rounded-md w-1/4"
                    value={term}
                    onChange={(e) => setTerm(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Year"
                    className="border p-1 rounded-md w-1/4"
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                />

                <button
                    className="bg-[#BB7B00] text-white px-3 py-2 rounded-md"
                    onClick={fetchResults}
                >
                    Fetch Results
                </button>

                <button
                    className="bg-green-500 text-white px-3 py-2 rounded-md"
                    onClick={openAddModal}
                >
                    Add Result
                </button>
            </div>



            <div className="overflow-x-auto mt-6">
                <table className="min-w-full table-auto border">
                    <thead className="bg-[#BB005A] text-white">
                        <tr>
                            <th className="text-start p-2">Reg Number</th>
                            <th className="text-start p-2">Name</th>
                            <th className="text-start p-2">Surname</th>
                            <th className="text-start p-2">Mark</th>
                            <th className="text-start p-2">Grade</th>
                            <th className="text-start p-2">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {results.map((result, index) => (
                            <tr key={result.ResultID} className={index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}>
                                <td className="p-2 border">{result.StudentRegNumber}</td>
                                <td className="p-2 border">{result.Name}</td>
                                <td className="p-2 border">{result.Surname}</td>
                                <td className="p-2 border">{result.AverageMark}</td>
                                <td className="p-2 border">{result.Grade}</td>
                                <td className="p-2 border space-x-2">
                                    <button
                                        className="bg-blue-500 text-white px-2 py-1 rounded"
                                        onClick={() => openEditModal(result)}
                                    >
                                        Edit
                                    </button>
                                    <button
                                        className="bg-red-500 text-white px-2 py-1 rounded"
                                        onClick={() => {
                                            setDeleteModalVisible(true);
                                            setResultToDelete(result.ResultID);
                                        }}
                                    >
                                        Delete
                                    </button>
                                    <button
                                        className="bg-green-500 text-white px-2 py-1 rounded"
                                        onClick={() => openViewModal(result)} // View button to open the modal
                                    >
                                        View
                                    </button>
                                </td>

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>



            {/* Add/Edit Modal */}
            {isModalVisible && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 sm:p-6 md:p-8">
                    <div className="bg-white p-8 rounded-lg w-full max-w-2xl sm:max-w-lg md:max-w-xl lg:max-w-2xl shadow-lg max-h-[90vh] overflow-y-auto">
                        <h3 className="text-2xl font-bold mb-6 text-center text-[#11024D]">
                            {isEditMode ? 'Edit Result' : 'Add Result'}
                        </h3>

                        {/* Term and Year Selectors */}
                        <div className="flex flex-wrap md:flex-nowrap mb-6">
                            <div className="w-full md:w-1/2 mb-4 md:mb-0 md:mr-4">
                                <label className="block text-lg mb-2 font-semibold text-gray-700">Select Term</label>
                                <select
                                    className="border border-gray-300 p-3 rounded-md w-full text-lg focus:outline-none focus:ring-2 focus:ring-[#11024D] transition"
                                    value={term}
                                    onChange={(e) => setTerm(e.target.value)}
                                >
                                    <option value="">Select Term</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                </select>
                            </div>
                            <div className="w-full md:w-1/2">
                                <label className="block text-lg mb-2 font-semibold text-gray-700">Select Year</label>
                                <select
                                    className="border border-gray-300 p-3 rounded-md w-full text-lg focus:outline-none focus:ring-2 focus:ring-[#11024D] transition"
                                    value={year}
                                    onChange={(e) => setYear(e.target.value)}
                                >
                                    <option value="">Select Year</option>
                                    {[...Array(21).keys()].map((i) => (
                                        <option key={i} value={2020 + i}>
                                            {2020 + i}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        {/* Student Selector */}
                        {!isEditMode && (
                            <div className="mb-4">
                                <label className="block text-lg mb-2 font-semibold text-gray-700">Select Student</label>
                                <select
                                    className="border border-gray-300 p-3 rounded-md w-full text-lg focus:outline-none focus:ring-2 focus:ring-[#11024D] transition"
                                    value={selectedStudent?.RegNumber || ''}
                                    onChange={(e) => {
                                        const student = students.find((s) => s.RegNumber === e.target.value);
                                        setSelectedStudent(student);
                                    }}
                                >
                                    <option value="">Select Student</option>
                                    {students.map((student) => (
                                        <option key={student.RegNumber} value={student.RegNumber}>
                                            {student.Name} {student.Surname}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}

                        {/* Comment Input */}
                        <div className="mb-4">
                            <label className="block text-lg mb-2 font-semibold text-gray-700">Comment</label>
                            <input
                                type="text"
                                className="border border-gray-300 p-3 rounded-md w-full text-lg focus:outline-none focus:ring-2 focus:ring-[#11024D] transition"
                                placeholder="Enter Comment"
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                            />
                        </div>

                        {/* Paper Marks */}
                        {newPaperMarks.map((paper, index) => (
                            <div key={index} className="flex items-center mb-4">
                                <div className="w-full md:w-1/3 mb-2 md:mb-0 md:mr-2">
                                    <label className="block text-lg mb-2 font-semibold text-gray-700">Paper Name</label>
                                    <input
                                        type="text"
                                        className="border border-gray-300 p-3 rounded-md w-full text-lg focus:outline-none focus:ring-2 focus:ring-[#11024D] transition"
                                        placeholder="Paper Name"
                                        value={paper.PaperName}
                                        onChange={(e) => {
                                            const newMarks = [...newPaperMarks];
                                            newMarks[index].PaperName = e.target.value;
                                            setNewPaperMarks(newMarks);
                                        }}
                                    />
                                </div>

                                <div className="w-full md:w-1/3 mb-2 md:mb-0 md:mr-2">
                                    <label className="block text-lg mb-2 font-semibold text-gray-700">Mark</label>
                                    <input
                                        type="number"
                                        className="border border-gray-300 p-3 rounded-md w-full text-lg focus:outline-none focus:ring-2 focus:ring-[#11024D] transition"
                                        placeholder="Mark"
                                        value={paper.Mark}
                                        onChange={(e) => {
                                            const newMarks = [...newPaperMarks];
                                            newMarks[index].Mark = e.target.value;
                                            setNewPaperMarks(newMarks);
                                        }}
                                    />
                                </div>

                                <button
                                    className="bg-red-500 text-white flex items-center mt-8 px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-red-700 transition"
                                    onClick={() => handleRemovePaperMark(index)}
                                >
                                    Remove
                                </button>
                            </div>
                        ))}

                        <button
                            className="w-full bg-gray-200 text-gray-700 text-lg px-4 py-3 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 transition mb-6"
                            onClick={handleAddPaperMark}
                        >
                            Add Paper Mark
                        </button>

                        {/* Action Buttons */}
                        <div className="flex justify-between items-center">
                            <button
                                className="bg-blue-600 text-white text-lg px-6 py-3 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 transition"
                                onClick={isEditMode ? handleEditResult : handleAddResult}
                            >
                                {isEditMode ? 'Save Changes' : 'Save'}
                            </button>
                            <button
                                className="bg-gray-300 text-black text-lg px-6 py-3 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 transition"
                                onClick={() => setIsModalVisible(false)}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}


            {isViewModalVisible && selectedResult && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 sm:p-6 md:p-8">
                    <div className="bg-white p-8 rounded-lg w-full max-w-2xl sm:max-w-lg md:max-w-xl lg:max-w-2xl shadow-lg">
                        <h3 className="text-2xl font-bold mb-6 text-center text-[#11024D]">
                            Paper Marks for {selectedResult.Name} {selectedResult.Surname}
                        </h3>

                        <div className="overflow-x-auto">
                            <table className="min-w-full table-auto border">
                                <thead className="bg-[#BB005A] text-white">
                                    <tr>
                                        <th className="text-start p-2">Paper Name</th>
                                        <th className="text-start p-2">Mark</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedResult.PaperMarks.map((paper, index) => (
                                        <tr key={index} className={index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}>
                                            <td className="p-2 border">{paper.PaperName}</td>
                                            <td className="p-2 border">{paper.Mark}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="flex justify-between items-center mt-4">
                            <button
                                className="bg-gray-300 text-black px-6 py-3 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 transition"
                                onClick={() => setIsViewModalVisible(false)}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {successModalVisible && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
                    <div className="bg-white p-6 rounded-md text-center">
                        <h3 className="text-lg font-bold">Result added successfully!</h3>
                    </div>
                </div>
            )}

            {/* Delete Confirmation Modal */}
            {deleteModalVisible && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-6 rounded-lg w-full max-w-md">
                        <h3 className="text-lg font-bold mb-4">Confirm Delete</h3>
                        <p>Are you sure you want to delete this result?</p>
                        <div className="flex justify-between mt-4">
                            <button
                                className="bg-red-500 text-white px-4 py-2 rounded-md"
                                onClick={handleDeleteResult}
                            >
                                Yes
                            </button>
                            <button
                                className="bg-gray-300 text-black px-4 py-2 rounded-md"
                                onClick={() => setDeleteModalVisible(false)}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ResultsSubjectLevel;
