import React from 'react';

const Help = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-2xl font-bold mb-4">Need Help?</h1>
      <p className="text-lg mb-6">Click the link below to watch the help video.</p>
      <a
        href="https://youtu.be/cOpYQKtzpi8?si=hTM0GJiYRvPcmEt1"
        target="_blank"
        rel="noopener noreferrer"
        className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-300"
      >
        Watch Help Video
      </a>
    </div>
  );
};

export default Help;
