import React, { useState } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams from react-router-dom
import StudentsSubjectLevel from '../components/StudentsSubjectLevel';
import ResultsSubjectLevel from '../components/ResultsSubjectLevel';

const SubjectLevelClassDetails = () => {
  const { classId } = useParams(); // Get classId from the route parameters
  const [activeSection, setActiveSection] = useState('students'); // Default to 'students' section

  const renderSection = () => {
    switch (activeSection) {
      case 'students':
        return <StudentsSubjectLevel classId={classId} />; // Pass classId as a prop to StudentsSubjectLevel
      case 'results':
        return <ResultsSubjectLevel classId={classId} />; // Pass classId as a prop to ResultsSubjectLevel
      default:
        return <StudentsSubjectLevel classId={classId} />; // Default to students if nothing is selected
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 p-6">
      {/* Clickable Tabs for Sections */}
      <div className="flex justify-center space-x-4 mb-6">
        <button
          className={`px-4 py-2 rounded ${activeSection === 'students' ? 'bg-purple-600 text-white' : 'bg-gray-200'}`}
          onClick={() => setActiveSection('students')}
        >
          Students
        </button>
        <button
          className={`px-4 py-2 rounded ${activeSection === 'results' ? 'bg-purple-600 text-white' : 'bg-gray-200'}`}
          onClick={() => setActiveSection('results')}
        >
          Results
        </button>
      </div>

      {/* Section Content */}
      <div className="bg-white p-4 rounded-lg shadow-md">
        {renderSection()}
      </div>
    </div>
  );
};

export default SubjectLevelClassDetails;
