import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { BASE_URL } from '../context/Api';
import AuthContext from '../context/AuthContext';

const Attendance = ({ classId }) => {
  const { user } = useContext(AuthContext);
  const [students, setStudents] = useState([]);
  const [date, setDate] = useState(new Date());
  const [term, setTerm] = useState('');
  const [year, setYear] = useState(new Date().getFullYear().toString());
  const [attendance, setAttendance] = useState({});
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    fetchStudents();
  }, [classId]);

  const fetchStudents = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/gradelevelclasses-students/get-all-students/${classId}`);
      setStudents(response.data.students);
    } catch (error) {
      console.error('Error fetching students:', error);
    }
  };

  const handleAttendanceChange = (regNumber, status) => {
    setAttendance((prev) => ({ ...prev, [regNumber]: status }));
  };

  const handleMarkAttendance = async () => {
    if (!term || !year || !date) {
      alert('Please fill in all fields');
      return;
    }

    try {
      const requests = students.map((student) => {
        const status = attendance[student.RegNumber] || 'Absent';
        return axios.post(`${BASE_URL}/attendance`, {
          RegNumber: student.RegNumber,
          classId,
          date: date.toISOString().split('T')[0],
          term,
          year,
          status,
        });
      });

      await Promise.all(requests);
      setModalVisible(true); // Show modal on success

      // Clear inputs after marking attendance
      setTerm('');
      setYear(new Date().getFullYear().toString());
      setAttendance({});
    } catch (error) {
      console.error('Error marking attendance:', error);
      alert('Failed to mark attendance');
    }
  };

  return (
    <div className="p-4 bg-white">
      <h2 className="text-3xl font-bold mb-4 text-[#11024D]">Mark Attendance</h2>
      
      <div className="flex flex-col md:flex-row md:space-x-4 mb-4 space-y-4 md:space-y-0">
        <input
          type="text"
          className="border border-gray-300 p-2 rounded-md w-full text-[#11024D]"
          placeholder="Term"
          value={term}
          onChange={(e) => setTerm(e.target.value)}
        />
        <input
          type="text"
          className="border border-gray-300 p-2 rounded-md w-full text-[#11024D]"
          placeholder="Year"
          value={year}
          onChange={(e) => setYear(e.target.value)}
        />
      </div>

      <div className="mb-4">
        <input
          type="date"
          className="border border-gray-300 p-2 rounded-md w-full text-[#11024D]"
          value={date.toISOString().split('T')[0]}
          onChange={(e) => setDate(new Date(e.target.value))}
        />
      </div>

      <div className="overflow-x-auto border border-gray-300 rounded-md">
        <table className="min-w-full table-auto">
          <thead className="bg-[#11024D] text-white">
            <tr>
              <th className="p-2">Reg Number</th>
              <th className="p-2">Name</th>
              <th className="p-2">Surname</th>
              <th className="p-2">Gender</th>
              <th className="p-2">Present</th>
              <th className="p-2">Absent</th>
            </tr>
          </thead>
          <tbody>
            {students.map((student, index) => (
              <tr key={student.RegNumber} className={index % 2 === 0 ? 'bg-[#fff6cc]' : 'bg-white'}>
                <td className="p-2 text-center border border-gray-300">{student.RegNumber}</td>
                <td className="p-2 text-center border border-gray-300">{student.Name}</td>
                <td className="p-2 text-center border border-gray-300">{student.Surname}</td>
                <td className="p-2 text-center border border-gray-300">{student.Gender}</td>
                <td className="p-2 text-center border border-gray-300">
                  <input
                    type="radio"
                    name={`attendance-${student.RegNumber}`}
                    checked={attendance[student.RegNumber] === 'Present'}
                    onChange={() => handleAttendanceChange(student.RegNumber, 'Present')}
                  />
                </td>
                <td className="p-2 text-center border border-gray-300">
                  <input
                    type="radio"
                    name={`attendance-${student.RegNumber}`}
                    checked={attendance[student.RegNumber] === 'Absent'}
                    onChange={() => handleAttendanceChange(student.RegNumber, 'Absent')}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <button
        className="bg-[#BB005A] text-white px-4 py-2 rounded-md w-full mt-4 hover:bg-[#a10049]"
        onClick={handleMarkAttendance}
      >
        Mark Attendance
      </button>

      {/* Success Modal */}
      {modalVisible && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg w-full max-w-md">
            <h3 className="text-xl font-bold mb-4 text-[#11024D]">Success</h3>
            <p className="text-[#11024D]">Attendance marked successfully!</p>
            <button
              className="bg-[#BB005A] text-white px-4 py-2 rounded-md mt-4 hover:bg-[#a10049]"
              onClick={() => setModalVisible(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Attendance;
