import React, { useState, useContext } from 'react';
import axios from 'axios';
import { BASE_URL } from '../context/Api'; // Ensure this is the correct path for your BASE_URL
import AuthContext from '../context/AuthContext';

const SchemeBooks = () => {
  const { employeeNumber } = useContext(AuthContext); // Fetch the employee number from the AuthContext
  const [description, setDescription] = useState('');
  const [classText, setClassText] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Validation function
  const validateInputs = () => {
    if (!description || !classText || !selectedDocument) {
      alert('Please fill all fields and select a document.');
      return false;
    }
    return true;
  };

  // Handle document selection
  const handleDocumentUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedDocument(file);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateInputs()) return;

    setIsLoading(true);

    const formData = new FormData();
    formData.append('employeeNumber', employeeNumber); // AuthContext data
    formData.append('description', description);
    formData.append('class', classText);
    formData.append('document', selectedDocument);
    formData.append('date', selectedDate.toISOString().split('T')[0]); // Convert to yyyy-mm-dd format

    try {
      const { data } = await axios.post(`${BASE_URL}/scheme-books`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setMessage('Scheme book uploaded successfully!');
      setDescription('');
      setClassText('');
      setSelectedDocument(null);
      setSelectedDate(new Date());
    } catch (error) {
      console.error('Error uploading document:', error);
      setMessage('Error uploading document.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="p-6 bg-white">
      <h1 className="text-2xl font-bold mb-4">Upload Scheme Book</h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="flex flex-col">
          <label className="mb-2 font-semibold">Description</label>
          <input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="p-2 border border-gray-300 rounded"
            placeholder="Enter description"
          />
        </div>
        <div className="flex flex-col">
          <label className="mb-2 font-semibold">Class</label>
          <input
            type="text"
            value={classText}
            onChange={(e) => setClassText(e.target.value)}
            className="p-2 border border-gray-300 rounded"
            placeholder="Enter class"
          />
        </div>
        <div className="flex flex-col">
          <label className="mb-2 font-semibold">Date</label>
          <input
            type="date"
            value={selectedDate.toISOString().split('T')[0]}
            onChange={(e) => setSelectedDate(new Date(e.target.value))}
            className="p-2 border border-gray-300 rounded"
          />
        </div>
        <div className="flex flex-col">
          <label className="mb-2 font-semibold">Select Document</label>
          <input
            type="file"
            onChange={handleDocumentUpload}
            className="p-2 border border-gray-300 rounded"
            accept=".pdf,.doc,.docx"
          />
        </div>
        <button
          type="submit"
          className="bg-[#BB005A] text-white px-4 py-2 rounded"
          disabled={isLoading}
        >
          {isLoading ? 'Uploading...' : 'Upload Scheme Book'}
        </button>
      </form>
      {message && (
        <div className="mt-4 p-2 bg-gray-100 border border-gray-300 rounded">
          {message}
        </div>
      )}
    </div>
  );
};

export default SchemeBooks;
