import React, { useState } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams to access route parameters
import StudentsFormLevel from '../components/StudentsFormLevel';
import Attendance from '../components/Attendance';
import ResultsFormLevel from '../components/ResultsFormLevel';

const GradeLevelClassDetails = () => {
  const { classId } = useParams(); // Get classId from the URL parameters
  const [activeSection, setActiveSection] = useState('students'); // Default to 'students' section

  const renderSection = () => {
    switch (activeSection) {
      case 'students':
        return <StudentsFormLevel classId={classId} />; // Pass classId as a prop
      case 'attendance':
        return <Attendance classId={classId} />;
      case 'results':
        return <ResultsFormLevel classId={classId} />;
      default:
        return <StudentsFormLevel classId={classId} />; // Pass classId as a prop
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 p-6">
      {/* Clickable Tabs for Sections */}
      <div className="flex justify-center space-x-4 mb-6">
        <button
          className={`px-4 py-2 rounded ${activeSection === 'students' ? 'bg-purple-600 text-white' : 'bg-gray-200'}`}
          onClick={() => setActiveSection('students')}
        >
          Students
        </button>
        <button
          className={`px-4 py-2 rounded ${activeSection === 'attendance' ? 'bg-purple-600 text-white' : 'bg-gray-200'}`}
          onClick={() => setActiveSection('attendance')}
        >
          Attendance
        </button>
        <button
          className={`px-4 py-2 rounded ${activeSection === 'results' ? 'bg-purple-600 text-white' : 'bg-gray-200'}`}
          onClick={() => setActiveSection('results')}
        >
          Results
        </button>
      </div>

      {/* Section Content */}
      <div className="bg-white p-4 rounded-lg shadow-md">
        {renderSection()}
      </div>
    </div>
  );
};

export default GradeLevelClassDetails;
