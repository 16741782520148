import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { BASE_URL } from '../context/Api';
import AuthContext from '../context/AuthContext';
import { FaUserCircle } from 'react-icons/fa'; // Importing a user icon

const Profile = () => {
  const { employeeNumber } = useContext(AuthContext); // Assuming user has the employee number
  const [profileData, setProfileData] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/teachers/get-teacher-by-employeenumber/${employeeNumber}`);
        setProfileData(response.data);
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };

    if (employeeNumber) {
      fetchProfile();
    }
  }, [employeeNumber]);

  if (!profileData) {
    return <div className="flex justify-center items-center min-h-screen">Loading...</div>;
  }

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      {/* Profile Header */}
      <div className="bg-white shadow-lg rounded-lg p-6 w-full">
        <div className="flex items-center space-x-4">
          <FaUserCircle className="text-6xl text-gray-500" />
          <div>
            <h1 className="text-3xl font-bold">Your Profile</h1>
            <p className="text-sm text-gray-600">Employee Number: {profileData.EmployeeNumber}</p>
          </div>
        </div>
      </div>

      {/* Profile Details */}
      <div className="bg-white shadow-lg rounded-lg p-6 mt-6 w-full">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <strong>Name:</strong> <span>{profileData.Name} {profileData.Surname}</span>
          </div>
          <div>
            <strong>Department:</strong> <span>{profileData.Department}</span>
          </div>
          <div>
            <strong>Role:</strong> <span>{profileData.Role}</span>
          </div>
          <div>
            <strong>Date of Birth:</strong> <span>{new Date(profileData.DateOfBirth).toLocaleDateString()}</span>
          </div>
          <div>
            <strong>Address:</strong> <span>{profileData.Address}</span>
          </div>
          <div>
            <strong>Phone Number:</strong> <span>{profileData.PhoneNumber}</span>
          </div>
          <div>
            <strong>National ID Number:</strong> <span>{profileData.NationalIDNumber}</span>
          </div>
          <div>
            <strong>Gender:</strong> <span>{profileData.Gender}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
