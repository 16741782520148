import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { BASE_URL } from '../context/Api';
import AuthContext from '../context/AuthContext';

const Notifications = () => {
  const { user } = useContext(AuthContext);
  const [announcements, setAnnouncements] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const announcementsPerPage = 5;

  useEffect(() => {
    fetchAnnouncements();
  }, [page]);

  const fetchAnnouncements = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/announcements/teacher-announcements`, {
        params: { page, limit: announcementsPerPage },
      });
      if (response.data.length < announcementsPerPage) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      setAnnouncements(response.data);
    } catch (error) {
      console.error('Error fetching announcements:', error);
    }
    setLoading(false);
  };

  const openModal = (announcement) => {
    setSelectedAnnouncement(announcement);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedAnnouncement(null);
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (hasMore) {
      setPage(page + 1);
    }
  };

  const truncateText = (text, length) => {
    if (text.length <= length) {
      return text;
    }
    return text.substring(0, length) + '...';
  };

  return (
    <div className="max-w-5xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h1 className="text-3xl font-bold text-gray-900 mb-6">Teacher Announcements</h1>

      <div className="overflow-auto h-96">
        {announcements.map((announcement, index) => (
          <div
            key={announcement.AnnouncementID ? announcement.AnnouncementID : index}
            className="bg-pink-100 border-l-4 border-pink-600 p-4 mb-4 rounded-lg hover:bg-pink-200 cursor-pointer"
            onClick={() => openModal(announcement)}
          >
            <div className="text-gray-600 text-sm">{new Date(announcement.Date).toLocaleDateString()}</div>
            <div className="text-gray-900 text-lg font-semibold mt-1">
              {truncateText(announcement.Description, 100)}
            </div>
          </div>
        ))}
        {loading && <div className="text-center text-pink-600">Loading...</div>}
      </div>

      {/* Modal for showing selected announcement details */}
      {isModalVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-6 z-50">
          <div className="bg-white p-6 rounded-lg max-w-lg w-full shadow-lg relative">
            {selectedAnnouncement && (
              <div>
                <h2 className="text-xl font-bold mb-4">Announcement Details</h2>
                <div className="text-gray-500 text-sm mb-2">
                  {new Date(selectedAnnouncement.Date).toLocaleDateString()}
                </div>
                <p className="text-gray-800">{selectedAnnouncement.Description}</p>
                <button
                  className="bg-pink-600 text-white py-2 px-4 rounded-lg mt-4"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Pagination Controls */}
      <div className="flex justify-between items-center mt-6">
        <button
          className={`px-4 py-2 bg-pink-600 text-white rounded-lg ${page === 1 && 'opacity-50 cursor-not-allowed'}`}
          disabled={page === 1}
          onClick={handlePreviousPage}
        >
          Previous
        </button>
        <span className="text-gray-900 text-lg font-semibold">{page}</span>
        <button
          className={`px-4 py-2 bg-pink-600 text-white rounded-lg ${!hasMore && 'opacity-50 cursor-not-allowed'}`}
          disabled={!hasMore}
          onClick={handleNextPage}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Notifications;
