import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import { BASE_URL } from '../context/Api';
import { FaUserAlt } from 'react-icons/fa';

const Homepage = () => {
  const { employeeNumber } = useContext(AuthContext);
  const [teacherData, setTeacherData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTeacherData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/teachers/${employeeNumber}`);
        setTeacherData(response.data);
      } catch (error) {
        console.error('Error fetching teacher data:', error);
      }
    };

    if (employeeNumber) {
      fetchTeacherData();
    }
  }, [employeeNumber]);

  const handleClassClick = (classID, classType) => {
    if (classType === 'Subject Level') {
      navigate(`/subject-level/${classID}`);
    } else if (classType === 'Form Level') {
      navigate(`/form-level/${classID}`);
    }
  };

  if (!teacherData) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="text-gray-700 text-xl">Loading...</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header Section */}
      <div className="p-8 bg-white shadow-md flex flex-col md:flex-row items-center md:justify-between">
        <div className="mb-4 md:mb-0">
          <h1 className="text-3xl font-semibold text-gray-900 mb-2">
            Welcome, {teacherData.teacherName}!
          </h1>
          <p className="text-gray-700">
            Employee Number: <span className="font-medium">{teacherData.employeeNumber}</span>
          </p>
        </div>
        {/* Mini Profile Section */}
        <div className="flex items-center justify-center">
          <div className="w-16 h-16 bg-gray-200 rounded-full flex items-center justify-center">
            <FaUserAlt className="text-gray-600 text-2xl" />
          </div>
        </div>
      </div>

      {/* Classes Section */}
      <div className="p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Form Level Classes */}
          <div>
            <h3 className="text-2xl font-semibold text-gray-800 mb-6">Form Level Classes</h3>
            <ul className="space-y-4">
              {teacherData.classes
                .filter((cls) => cls.ClassType === 'Form Level')
                .map((cls) => (
                  <li
                    key={cls.ClassID}
                    className="p-5 bg-white border border-gray-200 rounded-lg shadow-sm hover:shadow-md transition-shadow cursor-pointer"
                    onClick={() => handleClassClick(cls.ClassID, cls.ClassType)}
                  >
                    <div className="flex justify-between items-center">
                      <span className="text-lg font-semibold text-gray-900">{cls.ClassName}</span>
                      <span className="text-sm text-gray-500">Form {cls.Form}</span>
                    </div>
                  </li>
                ))}
            </ul>
          </div>

          {/* Subject Level Classes */}
          <div>
            <h3 className="text-2xl font-semibold text-gray-800 mb-6">Subject Level Classes</h3>
            <ul className="space-y-4">
              {teacherData.classes
                .filter((cls) => cls.ClassType === 'Subject Level')
                .map((cls) => (
                  <li
                    key={cls.ClassID}
                    className="p-5 bg-white border border-gray-200 rounded-lg shadow-sm hover:shadow-md transition-shadow cursor-pointer"
                    onClick={() => handleClassClick(cls.ClassID, cls.ClassType)}
                  >
                    <div className="flex justify-between items-center">
                      <span className="text-lg font-semibold text-gray-900">{cls.ClassName}</span>
                      <span className="text-sm text-gray-500">Form {cls.Form}</span>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
